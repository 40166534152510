<!-- @format -->
<script lang="ts">
  import type { StoresKey } from "~/stores";

  import Title from "../common/Title.svelte";
  import TwoItemCardLink from "../carousels/TwoItemCardLink.svelte";
  import TwoItemCard from "../common/TwoItemCard.svelte";
  import type { SurfHouse } from "~/mall-of-tripla";
  import type { CarouselInfo } from "../carousels/utils";
  import { onMount } from "svelte";

  let class_list = "";
  export { class_list as class };
  export let request_store_key: StoresKey;
  export let carouselInfo: CarouselInfo;
  export let surfHouseEvents: SurfHouse[];
  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
    logo,
  } = carouselInfo;

  $: if (!surfHouseEvents.some(obj => obj.id === 0)) surfHouseEvents.splice(1, 0, { id: 0 });

  let currentIndex = 0;

  function nextSlide() {
    currentIndex = (currentIndex + 1) % surfHouseEvents.length;
    updateCarousel();
  };

  function prevSlide() {
    currentIndex = (currentIndex - 1 + surfHouseEvents.length) % surfHouseEvents.length;
    updateCarousel();
  };
  function updateCarousel() {
    const track = document.querySelector(".carousel-track");
    if (track) {
      const slideWidth = track.clientWidth / 2.5;
      track.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
    }
  };

  onMount(() => {
    updateCarousel();
  });

</script>

<div class="lift-container">
  {#if surfHouseEvents && surfHouseEvents.length && linkTextbox}
    <div class="py-6 {class_list}">
      {#if linkTitle}
        <Title {linkTitle} {titleTextColor} />
      {/if}
      <div class="carousel-container">
        <div class="carousel-track">
          {#each surfHouseEvents as event}
            <div class="carousel-slide">
              {#if event.id !== 0}
                <TwoItemCard
                  props={{
                    image: event.image.thumbnail,
                    link: event.link,
                    title: event.heading,
                    description: event.excerpt.plain,
                  }}
                  {request_store_key}
                />
              {:else}
                <TwoItemCardLink
                  {textColor}
                  backgroundColor={textboxBackgroundColor}
                  {linkTextbox}
                  {logo}
                />
              {/if}
            </div>
          {/each}
        </div>
        <div class="carousel-buttons">
          <button on:click={prevSlide}>
            <i class="bi bi-chevron-left shadow"></i>
          </button>
          <button on:click={nextSlide}>
            <i class="bi bi-chevron-right shadow"></i>
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  $gap: 20px;
  .lift-container {
    background-color: var(--brand-color-4);
    padding: 0 8%;
    .carousel-container {
      overflow: hidden;
      position: relative;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      .carousel-track {
        display: flex;
        gap: $gap;
        transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
      .carousel-slide {
        flex: 0 0 calc(100% / 2.5);
        max-width: calc(100% / 2.5);
        opacity: 0.7;
        transition: opacity 0.4s ease, transform 0.4s ease;
        &.active {
          opacity: 1;
          transform: scale(1.02);
        }
      }
      .carousel-buttons {
        position: absolute;
        top: 40%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          width: 40px;
          height: 40px;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            width: 42px;
            height: 42px;
          }
        }
      }
    }
  }
</style>
